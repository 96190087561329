<template>
  <div class="form_area">
    <form id="contactForm" @submit="checkForm">
      <div class="input_field">
        <input
          type="email"
          placeholder="Your email"
          name="email"
          id="email"
          v-model="email"
          required
        />
      </div>
      <div class="input_field">
        <textarea
          placeholder="Briefly explain you subject"
          name="message"
          id="message"
          v-model="message"
          required
        ></textarea>
      </div>
      <div class="submit_btn">
        <input type="submit" value="let us know" />
      </div>
      <div id="pre_loder" style="display: none;"><img style="margin: 0 auto;" src="../assets/pre-loder.gif" alt="loder" /></div>
      <p
        id="messageeee"
        style="color: #fff; text-align: center; margin: 10px 0"
      ></p>
    </form>
  </div>
</template>
<script>
export default {
  name: "BottomForm",
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      console.log(this.email, this.message);
      document.getElementById("pre_loder").style.display = "block";
      // POST request using fetch()
      fetch("https://dev.slyd.com/mail-send-vue.php", {
        method: "POST",
        body: JSON.stringify({
          email: this.email,
          message: this.message,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          document.getElementById('email').value = "";
          document.getElementById('message').value = "";

          console.log(json.message);
          document.getElementById("pre_loder").style.display = "none";
          var theDiv = document.getElementById("messageeee");
          theDiv.innerHTML += json.message;
          setTimeout(() => {
            theDiv.innerHTML = "";
          }, "3000");
        });
    },
  },
};
</script>
<style scoped>
.form_area {
  width: 100%;
  max-width: 792px;
  margin: 48px auto 48px;
}

.form_area .input_field {
  width: 100%;
  display: block;
  margin: 0 0 24px;
  padding: 0;
}
.form_area .input_field input,
.form_area .input_field textarea {
  width: 100%;
  height: 64px;
  padding: 0px 24px;
  border-radius: 15px;
  border: 0;
  background: #fff;
  outline: none;

  color: #1f1f1f;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.form_area .input_field input,
.form_area .input_field textarea {
}

.form_area .input_field input::placeholder,
.form_area .input_field textarea::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.form_area .input_field input::-ms-input-placeholder,
.form_area .input_field textarea::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.form_area .input_field textarea {
  height: 120px;
  padding: 18px 24px;
}
.form_area .submit_btn {
  width: 100%;
  display: block;
  margin: 40px 0 0;
  padding: 0;
  text-align: center;
}
.form_area .submit_btn input[type="submit"] {
  border: 0;
  outline: none;
  height: 58px;
  padding: 0px 32px;
  border-radius: 15px;
  background: #5d5fea;

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
}
.form_area .submit_btn input[type="submit"]:hover {
  background: #7475e8;
  color: #fff;
}
</style>