<template>
    <div class="main_header">
        <div class="container">
            <div class="custom_row">
                <div class="tag_line" style="opacity: 0;">
                    Premiere cloud provider<br>in California
                </div>
                <div class="logo">
                    <svg width="120" height="58" viewBox="0 0 120 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_629_1786)">
                    <path d="M30.3604 28.1381V29.8838L2.72754 29.8628V28.1175L30.3604 28.1381Z" fill="white"/>
                    <path d="M7.01953 20.3773H21.3016V22.123H7.01953V20.3773Z" fill="white"/>
                    <path d="M15.3809 12.6371H24.8281V14.3828H15.3809V12.6371Z" fill="white"/>
                    <path d="M26.0692 37.623H11.7871V35.8777H26.0692V37.623Z" fill="white"/>
                    <path d="M17.707 45.3643H8.25977V43.6185H17.707V45.3643Z" fill="white"/>
                    </g>
                    <path d="M56.3546 25.0302C55.7764 24.0733 54.1885 23.1672 52.3606 23.1672C50.0028 23.1672 48.8725 24.1451 48.8725 25.388C48.8725 26.8453 50.6041 27.2519 52.6256 27.4912C56.1368 27.9208 59.409 28.8269 59.409 32.8159C59.409 36.5447 56.0886 38.1455 52.3375 38.1455C48.8966 38.1455 46.2497 37.0949 45 34.0378L47.6469 32.6743C48.3908 34.5143 50.34 35.3267 52.3847 35.3267C54.3802 35.3267 56.2563 34.6349 56.2563 32.8159C56.2563 31.241 54.598 30.5951 52.3606 30.3559C48.9217 29.9512 45.7458 29.0431 45.7458 25.2923C45.7458 21.8525 49.1606 20.4441 52.2652 20.4182C54.8861 20.4182 57.6053 21.1598 58.88 23.7384L56.3546 25.0302Z" fill="white"/>
                    <path d="M69.923 20.9923V34.8023H78.5844V37.7158H66.7471V20.9923H69.923Z" fill="white"/>
                    <path d="M89.5992 27.8958L94.073 20.9923H97.8974V21.1367L91.1853 30.8113V37.7158H88.0103V30.8113L81.541 21.1367V20.9923H85.3172L89.5992 27.8958Z" fill="white"/>
                    <path d="M107.218 34.6817H110.683C114.506 34.6817 116.022 31.9107 115.95 29.1876C115.877 26.583 114.338 23.9785 110.683 23.9785H107.218V34.6817ZM119.102 29.2105C119.174 33.4388 116.577 37.7158 110.683 37.7158H104.066V20.9923H110.683C116.456 20.9923 119.029 25.077 119.102 29.2105Z" fill="white"/>
                    <defs>
                    <clipPath id="clip0_629_1786">
                    <rect width="32.7273" height="32.7273" fill="white" transform="translate(0 12.6367)"/>
                    </clipPath>
                    </defs>
                    </svg>
                </div>
                <div class="button_group">
                    <a href="https://dev.cloud.slyd.com/slyd-dashboard-vue/gpucloud" target="_blank" class="without_border">Console</a>
                    <a href="https://dev.cloud.slyd.com/slyd-dashboard-vue/" target="_blank" class="with_border">Sign Up</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'CommonHeader'
}
</script>
<style scoped>
    .main_header{
        width:100%;
        margin:0;
        padding:35px 0;
        position:absolute;
        top:0;
        left:0;
        z-index: 99;
    }
    .main_header .custom_row{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .main_header .tag_line{
        color: #EAEAEA;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    .button_group .with_border{
        border-radius: 15px;
        border: 1px solid #FFF;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        padding:15px 32px;
        background:transparent;
        transition:all 0.3s ease-in-out;
        margin-left:10px;
        text-decoration: none;
    }
    .button_group .without_border{
        border-radius: 15px;
        border: 1px solid transparent;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        height: 58px;
        padding:0 32px 0 0;
        background:transparent;
        transition:all 0.3s ease-in-out;
        text-decoration: none;
    }
    .button_group .with_border:hover{
        background: #7475E8;
        color:#fff;
        border-color: #7475E8;
    }
    .button_group .with_border:active,
    .button_group .with_border:focus{
        background: #4446E8;
        border-color: #4446E8;
        color:#fff;
    }
    .button_group .without_border:hover{
        color: #7475E8;
    }
    .button_group .without_border:active,
    
    .button_group .without_border:focus{
        color:#4446E8;
    }

    @media only screen and (max-width:767px) {
        .main_header {
        padding: 25px 0;
    }
        .main_header .custom_row {
            justify-content: center;
        }
        .main_header .tag_line,
        .main_header .button_group {
            display: none;
        }

    }
</style>