<template>
  <div class="sticky_navigation">
    <div class="container">
      <div class="custom_row">
        <div class="back_to_top" id="back_to_top">
          <a href="#app"><span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Icon / ArrowUpOutlined">
                  <path id="Vector"
                    d="M13.5619 8.52287L8.37594 2.54631C8.32906 2.49223 8.2711 2.44886 8.206 2.41913C8.1409 2.38941 8.07016 2.37402 7.99859 2.37402C7.92702 2.37402 7.85629 2.38941 7.79119 2.41913C7.72608 2.44886 7.66813 2.49223 7.62125 2.54631L2.43687 8.52287C2.42121 8.54089 2.41105 8.56302 2.40761 8.58665C2.40417 8.61027 2.40759 8.63439 2.41747 8.65612C2.42735 8.67786 2.44327 8.69629 2.46333 8.70923C2.48339 8.72218 2.50675 8.72908 2.53062 8.72912H3.79625C3.86812 8.72912 3.93687 8.69787 3.98531 8.64318L7.40562 4.701V13.4994C7.40562 13.5682 7.46187 13.6244 7.53062 13.6244H8.46812C8.53687 13.6244 8.59312 13.5682 8.59312 13.4994V4.701L12.0134 8.64318C12.0603 8.69787 12.1291 8.72912 12.2025 8.72912H13.4681C13.5744 8.72912 13.6322 8.60412 13.5619 8.52287Z"
                    fill="white" />
                </g>
              </svg>
              TOP</span></a>
        </div>
        <div class="nagigation_and_logo" id="nagigation_and_logo">
          <div class="logo" v-on:click="menuOpenClose">
            <a>
              <svg width="70" height="20" viewBox="0 0 70 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_628_760)">
                  <path d="M17.7896 9.49631V10.5146L1.67041 10.5024V9.48429L17.7896 9.49631Z" fill="white" />
                  <path d="M4.17407 4.96896H12.5053V5.9873H4.17407V4.96896Z" fill="white" />
                  <path d="M9.05176 0.454314H14.5627V1.47266H9.05176V0.454314Z" fill="white" />
                  <path d="M15.2863 15.0293H6.95508V14.0112H15.2863V15.0293Z" fill="white" />
                  <path d="M10.4084 19.5449H4.89746V18.5266H10.4084V19.5449Z" fill="white" />
                </g>
                <g clip-path="url(#clip1_628_760)">
                  <path
                    d="M32.7939 7.47146C32.4567 6.91331 31.5304 6.38474 30.4641 6.38474C29.0887 6.38474 28.4294 6.95517 28.4294 7.68021C28.4294 8.53028 29.4394 8.76749 30.6187 8.90703C32.6669 9.15764 34.5757 9.68621 34.5757 12.0132C34.5757 14.1883 32.6388 15.1221 30.4506 15.1221C28.4434 15.1221 26.8994 14.5092 26.1704 12.7259L27.7144 11.9305C28.1484 13.0039 29.2854 13.4778 30.4782 13.4778C31.6422 13.4778 32.7366 13.0742 32.7366 12.0132C32.7366 11.0944 31.7692 10.7177 30.4641 10.5781C28.4581 10.342 26.6055 9.81236 26.6055 7.6244C26.6055 5.61783 28.5975 4.79623 30.4085 4.78116C31.9373 4.78116 33.5235 5.21373 34.2671 6.71795L32.7939 7.47146Z"
                    fill="white" />
                  <path d="M40.709 5.11569V13.1715H45.7615V14.8711H38.8564V5.11569H40.709Z" fill="white" />
                  <path
                    d="M52.1867 9.14276L54.7964 5.11569H57.0273V5.19997L53.1119 10.8435V14.8711H51.2599V10.8435L47.4861 5.19997V5.11569H49.6889L52.1867 9.14276Z"
                    fill="white" />
                  <path
                    d="M62.4643 13.1012H64.4855C66.7153 13.1012 67.6 11.4848 67.5578 9.89627C67.5151 8.37698 66.6175 6.85768 64.4855 6.85768H62.4643V13.1012ZM69.3964 9.90967C69.4385 12.3761 67.9237 14.8711 64.4855 14.8711H60.6257V5.11569H64.4855C67.8529 5.11569 69.3542 7.49844 69.3964 9.90967Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_628_760">
                    <rect width="19.0909" height="19.0909" fill="white" transform="translate(0.0795898 0.454102)" />
                  </clipPath>
                  <clipPath id="clip1_628_760">
                    <rect width="43.75" height="19.0909" fill="white" transform="translate(26.1704 0.454102)" />
                  </clipPath>
                </defs>
              </svg>
            </a>

            <a class="close_icon" id="close_icon">

              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_628_778)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.0608 1.82724C14.0612 1.82724 14.0615 1.8276 14.0622 1.82831L15.0924 2.85867C15.0931 2.85921 15.0933 2.85956 15.0935 2.8601C15.0936 2.86045 15.0936 2.86082 15.0935 2.86117C15.0935 2.86171 15.0931 2.86206 15.0924 2.86278L9.95491 8.00028L15.0924 13.1378C15.0931 13.1385 15.0933 13.1388 15.0935 13.1394C15.0936 13.1398 15.0936 13.1402 15.0935 13.1406C15.0935 13.141 15.0931 13.1413 15.0924 13.1421L14.062 14.1722C14.0615 14.173 14.0612 14.1731 14.0608 14.1733C14.0604 14.1734 14.06 14.1734 14.0595 14.1733C14.059 14.1733 14.0587 14.173 14.0579 14.1722L8.92044 9.03474L3.78294 14.1722C3.78223 14.173 3.78187 14.1731 3.78134 14.1733C3.78093 14.1734 3.78049 14.1734 3.78009 14.1733C3.77973 14.1733 3.77937 14.173 3.77866 14.1722L2.74848 13.1419C2.74776 13.1413 2.74759 13.141 2.74741 13.1406C2.74728 13.1402 2.74728 13.1398 2.74741 13.1394C2.74741 13.1388 2.74776 13.1385 2.74848 13.1378L7.88598 8.00028L2.74848 2.86278C2.74776 2.86206 2.74759 2.86171 2.74741 2.86117C2.74728 2.86076 2.74728 2.86033 2.74741 2.85992C2.74741 2.85956 2.74776 2.85921 2.74848 2.85849L3.77884 1.82831C3.77937 1.8276 3.77973 1.82742 3.78009 1.82724C3.78049 1.82712 3.78093 1.82712 3.78134 1.82724C3.78187 1.82724 3.78223 1.8276 3.78294 1.82831L8.92044 6.96581L14.0579 1.82831C14.0587 1.8276 14.059 1.82742 14.0595 1.82724C14.06 1.82712 14.0604 1.82712 14.0608 1.82724Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_628_778">
                    <path
                      d="M0.92041 4C0.92041 1.79086 2.71127 0 4.92041 0H12.9204C15.1295 0 16.9204 1.79086 16.9204 4V12C16.9204 14.2091 15.1295 16 12.9204 16H4.92041C2.71127 16 0.92041 14.2091 0.92041 12V4Z"
                      fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </a>
            <a class="hamburger_icon" id="hamburger_icon">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.0454 2.5H2.79541C2.72666 2.5 2.67041 2.55625 2.67041 2.625V3.625C2.67041 3.69375 2.72666 3.75 2.79541 3.75H15.0454C15.1142 3.75 15.1704 3.69375 15.1704 3.625V2.625C15.1704 2.55625 15.1142 2.5 15.0454 2.5ZM15.0454 12.25H2.79541C2.72666 12.25 2.67041 12.3062 2.67041 12.375V13.375C2.67041 13.4438 2.72666 13.5 2.79541 13.5H15.0454C15.1142 13.5 15.1704 13.4438 15.1704 13.375V12.375C15.1704 12.3062 15.1142 12.25 15.0454 12.25ZM15.0454 7.375H2.79541C2.72666 7.375 2.67041 7.43125 2.67041 7.5V8.5C2.67041 8.56875 2.72666 8.625 2.79541 8.625H15.0454C15.1142 8.625 15.1704 8.56875 15.1704 8.5V7.5C15.1704 7.43125 15.1142 7.375 15.0454 7.375Z"
                  fill="white" />
              </svg>
            </a>

          </div>
          <nav class="nav_sec">
            <ul>
              <li>
                <a href="#our_benefits"><span>our benefits</span></a>
              </li>
              <li>
                <a href="#who_we_are"><span>WHO WE ARE</span></a>
              </li>
              <li>
                <a href="#soluctions"><span>SOLUTIONS</span></a>
              </li>
              <li>
                <a href="#pricing"><span>PRICING</span></a>
              </li>
              <li>
                <a href="#faq"><span>FAQ</span></a>
              </li>
              <li>
                <a href="#help"><span>CONTACT</span></a>
              </li>
              <li class="active">
                <a href="#pricing"><span>RENT GPU</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="mobile_navigation" id="mobile_navigation">
    <div class="container">
      <div class="outer-bg">
        <div class="for_bg">
          <nav class="for_mobile">
            <ul>
              <li>
                <a v-on:click="closeMenu" href="#our_benefits"><span>our benefits</span></a>
              </li>
              <li>
                <a v-on:click="closeMenu" href="#who_we_are"><span>WHO WE ARE</span></a>
              </li>
              <li>
                <a v-on:click="closeMenu" href="#soluctions"><span>SOLUTIONS</span></a>
              </li>
              <li>
                <a v-on:click="closeMenu" href="#pricing"><span>PRICING</span></a>
              </li>
              <li>
                <a v-on:click="closeMenu" href="#faq"><span>FAQ</span></a>
              </li>
              <li>
                <a v-on:click="closeMenu" href="#help"><span>CONTACT</span></a>
              </li>
            </ul>
          </nav>
          <div class="header_btns">
            <ul>
              <li><a v-on:click="closeMenu" class="without_border" href="https://dev.cloud.slyd.com/slyd-dashboard-vue/gpucloud" target="_blank">Console</a></li>
              <li><a v-on:click="closeMenu" class="with_border" href="https://dev.cloud.slyd.com/slyd-dashboard-vue/" target="_blank">Sign up</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "StickyNav",
  methods: {
    // openMenu() {
    //   document.getElementById("hamburger_icon").style.display = "none";
    //   document.getElementById("close_icon").style.display = "block";
    //   document.getElementById('mobile_navigation').style.display = "block";
    //   var element = document.getElementById("nagigation_and_logo");
    //   element.classList.add("active_nav");
    // },
    menuOpenClose(){
      var x = document.getElementById("mobile_navigation");
      var element = document.getElementById("nagigation_and_logo");

      if (x.style.display === "block") {
        x.style.display = "none";
        element.classList.remove("active_nav"); 
        document.getElementById("hamburger_icon").style.display = "block";
        document.getElementById("close_icon").style.display = "none";
      } else {
        x.style.display = "block";
        element.classList.add("active_nav");
        document.getElementById("hamburger_icon").style.display = "none";
        document.getElementById("close_icon").style.display = "block";
      }
    },
    closeMenu() {
      document.getElementById("close_icon").style.display = "none";
      document.getElementById("hamburger_icon").style.display = "block";
      document.getElementById('mobile_navigation').style.display = "none";
      var element = document.getElementById("nagigation_and_logo");
      element.classList.remove("active_nav");
    },
  },
};
window.onscroll = function() {myFunction()};

function myFunction() {
  var testDiv = document.getElementById("our_benefits");
  console.log(testDiv.offsetTop);
  if (window.scrollY > testDiv.offsetTop ) {
      var element = document.getElementById("back_to_top");
      element.classList.add("remove");
  } else {
      var element2 = document.getElementById("back_to_top");
      element2.classList.remove("remove");
  }
}

</script>
<style scoped>
a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sticky_navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  padding: 15px 0;
}

.custom_row {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hamburger_icon svg {
  display: none;
}

.back_to_top {
  background-color: #333333;
  border-radius: 15px;
  padding: 8px;
  /* margin-right: 32px; */

  position:absolute;
  left: 45px;
  top: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.back_to_top.remove{
  visibility: visible;
  opacity: 1;
}

.back_to_top a {
  display: inline-block;
  background: #444;
  border-radius: 10px;
  padding: 8px;
}

.back_to_top a span {
  border-radius: 10px;
  background: #333;
  display: inline-block;
  padding: 7px 14px;

  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.back_to_top a span i {
  margin-right: 8px;
}

.back_to_top a:hover span {
  background: #0f0f0f;
}

.nagigation_and_logo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #333333;
  border-radius: 15px;
  padding: 8px;
}

.nagigation_and_logo .logo {
  display: flex;
  height: 48px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #0f0f0f;
  pointer-events: none;
}

.nagigation_and_logo .logo i {
  display: none;
}

.nav_sec ul {
  margin: 0;
  padding: 0;
}

.nav_sec ul li {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.nav_sec ul li a {
  display: inline-block;
  background: #444;
  border-radius: 10px;
  padding: 8px;
}

.nav_sec ul li a span {
  border-radius: 10px;
  background: #333;
  display: inline-block;
  padding: 7px 14px;

  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.nav_sec ul li a:hover span {
  background: #0f0f0f;
}

.nav_sec ul li.active a,
.nav_sec ul li.active a span {
  background: #5d5fea;
}

.mobile_navigation,
.close_icon {
  display: none;
}

@media only screen and (max-width: 1440px) {
  .back_to_top {
    display: none;
  }

  .sticky_navigation .custom_row {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 1024px) {

  .nagigation_and_logo .logo,
  .nav_sec {
    width: 50%;
  }

  .nav_sec ul li a,
  .nav_sec ul li {
    display: block;
    text-align: center;
  }

  .nav_sec ul li:not(:last-child) {
    display: none;
  }

  .hamburger_icon svg {
    display: inline-block;
  }

  .nagigation_and_logo .logo i {
    display: inline-block;
    color: #ffffff;
  }

  .nagigation_and_logo {
    width: 100%;
  }

  .mobile_navigation {
    border-radius: 15px 15px 0 0;
    position: fixed;
    bottom: 78px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: auto;
    z-index: 999999;
  }

  .outer-bg {
    background: #333;
    padding: 8px 8px 0;
    border-radius: 15px 15px 0 0;
  }

  .mobile_navigation .for_bg {
    display: flex;
    padding: 48px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 35px;
    align-self: stretch;
    border-radius: 10px;
    background: #0f0f0f;
    text-align: center;
    height: 100%;
  }

  .mobile_navigation .for_mobile ul li+li {
    margin-top: 30px;
  }

  .mobile_navigation .for_mobile ul li a {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 100% */
    text-transform: uppercase;
  }

  .nagigation_and_logo.active_nav {
    border-radius: 0 0 15px 15px;
  }

  .header_btns .with_border {
    border-radius: 15px;
    border: 1px solid transparent;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    padding: 15px 32px;
    background: #5d5fea;
    transition: all 0.3s ease-in-out;
    display: inline-block;
  }

  .header_btns .without_border {
    border-radius: 15px;
    border: 1px solid transparent;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    height: 58px;
    padding: 15px 32px;
    background: transparent;
    transition: all 0.3s ease-in-out;
    display: inline-block;
  }

  .header_btns .with_border:hover {
    background: #7475e8;
    color: #fff;
    border-color: #7475e8;
  }

  .header_btns .with_border:active,
  .header_btns .with_border:focus {
    background: #4446e8;
    border-color: #4446e8;
    color: #fff;
  }

  .header_btns .without_border:hover {
    color: #7475e8;
  }

  .header_btns .without_border:active,
  .header_btns .without_border:focus {
    color: #4446e8;
  }

  .nagigation_and_logo .logo {
    pointer-events: auto;
  }

}
</style>