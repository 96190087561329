<template>
  <Header/>
  <Navigation />
  <Home/>
</template>

<script>
import Header from './components/Header.vue'
import Navigation from './components/StickyNav.vue'
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Header,
    Navigation,
    Home
  }
}
</script>

<style>
@font-face {
    font-family: 'GilroyBold';
    src: url('./assets/fonts/GilroyBold.eot');
    src: url('./assets/fonts/GilroyBold.eot') format('embedded-opentype'),
         url('./assets/fonts/GilroyBold.woff2') format('woff2'),
         url('./assets/fonts/GilroyBold.woff') format('woff'),
         url('./assets/fonts/GilroyBold.ttf') format('truetype'),
         url('./assets/fonts/GilroyBold.svg#GilroyBold') format('svg');
}

#app {
  font-family: 'Rubik', sans-serif;
}
.common_btn{
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  padding: 15px 32px;
  border-radius: 15px;
  background: #5D5FEA;
  transition: all 0.3s ease-in-out;
}
.common_btn:hover{
  background: #7475E8;
  color:#fff;
}
.common_btn:active,
.common_btn:focus,
.btn-check:checked+.btn, 
.btn.active, .btn.show, 
.btn:first-child:active, 
:not(.btn-check)+.btn:active{
  background-color: #4446E8;
  color:#fff;
  border-color: #4446E8 ;
}
@media only screen and (max-width:1370px){
  .container{
    max-width: 1200px;
    width:100%;
  }
}
</style>
